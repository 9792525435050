import React from 'react';
import {graphql, PageProps} from 'gatsby';
import ProductPage from '../components/ProductPage';
import * as AppTypes from '../constants/apps';
import {getPageData} from '../utils';
import {BlocswaveQuery} from './__generated__/BlocswaveQuery';

const Blocswave = (props: PageProps<BlocswaveQuery>) => (
  <ProductPage data={getPageData(props.data)} app={AppTypes.BLOCSWAVE} />
);

export const pageQuery = graphql`
  query BlocswaveQuery {
    en: allContentfulAmpifyWebBlocswave(filter: {node_locale: {eq: "en-GB"}}) {
      edges {
        node {
          pageTitle
          metaKeywords
          metaDescription
          heroVideoUrl
          heroMobileImage {
            title
            fluid(maxWidth: 1400, quality: 80) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          heroVideoFrame {
            title
            fluid(maxWidth: 1400, quality: 80) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          introSubtitle
          introTitle
          introText
          introImage {
            title
            fluid(maxWidth: 1400, quality: 80) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          introButtonText
          introButtonUrl
          introButtonTitle
          soundpacksTitle
          soundpacksSubtitle
          soundpacksText
          proFeaturesSubtitle
          proFeaturesTitle
          proFeaturesText
          proFeaturesVideoUrl
          keyFeaturesTitle
          keyFeaturesText {
            raw
          }
          hardwareSubtitle
          hardwareTitle
          hardwareParagraph
          hardwareImage {
            ...AmpifyBodyImage
          }
          crossProduct1 {
            introTitle
            introText
            introSubtitle
            introImage {
              title
              fluid(maxWidth: 1400, quality: 80) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            relativeUrl
          }
          crossProduct2 {
            introTitle
            introText
            introSubtitle
            introImage {
              title
              fluid(maxWidth: 1400, quality: 80) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            relativeUrl
          }
          demoVideo
        }
      }
    }
  }
`;

export default Blocswave;
